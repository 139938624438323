<decimal-input
  class="w-44"
  [decimals]="9"
  [fullWidth]="true"
  [step]="0.5"
  [formControl]="calculation"
  addOn="calculator"
  [sbPopover]="calculator"
  [sbPopoverPosition]="'bottom'"
  [sbPopoverDisabled]="!calculation.enabled"
>
</decimal-input>

<ng-template #calculator>
  <sb-popover [title]="'Calculator' | translate">
    <div class="flex space-x-4">
      <span>{{ 'Days based on fulltime contract:' | translate }}</span>
      <decimal-input class="block w-16" [decimals]="1" [step]="0.5" [fullWidth]="false" [formControl]="vacationDays">
      </decimal-input>
    </div>
    <sb-popover-footer>
      <button sb-button [color]="'secondary'" (click)="closePopover()">{{ 'Close' | translate }}</button>
      <button sb-button [color]="'primary'" (click)="calculate()">{{ 'Calculate' | translate }}</button>
    </sb-popover-footer>
  </sb-popover>
</ng-template>
