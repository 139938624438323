import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, forwardRef, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DecimalInputComponent } from '@app/shared/decimal-input/decimal-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, PopoverDirective, PopoverModule } from '@sb/ui';
import { Subscription } from 'rxjs';

export const VACATION_DAY_CONSTANT = 0.003846153846154;

export const VACATION_CALC_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimeOffAccrualCalculationInputComponent),
  multi: true,
};
@Component({
  selector: 'time-off-accrual-calculation-input',
  templateUrl: './time-off-accrual-calculation-input.component.html',
  providers: [VACATION_CALC_CONTROL_VALUE_ACCESSOR],
  imports: [CommonModule, PopoverModule, TranslateModule, ReactiveFormsModule, DecimalInputComponent, ButtonComponent],
  standalone: true,
})
export class TimeOffAccrualCalculationInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @ViewChild(PopoverDirective, { static: true })
  public popover: PopoverDirective;

  private subscription = new Subscription();
  public calculation = new FormControl(0.096153846);
  public vacationDays = new FormControl(25);
  public value: number;

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private onChange: (value: any) => void = () => {};
  // Implemented as part of ControlValueAccessor.  Called when the checkbox is blurred.  Needed to properly implement ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private onTouched: () => any = () => {};

  public ngAfterViewInit() {
    this.subscription.add(
      this.calculation.valueChanges.subscribe((value) => {
        this.onChange(value);
      }),
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public writeValue(value: number): void {
    this.calculation.setValue(value, { onlySelf: true, emitEvent: false });
    const vacationDays = value / VACATION_DAY_CONSTANT;
    this.vacationDays.patchValue(vacationDays, { emitEvent: false, onlySelf: true });
  }

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.calculation.disable() : this.calculation.enable();
  }

  public calculate() {
    const value = this.vacationDays.value * VACATION_DAY_CONSTANT;

    this.calculation.setValue(value, { onlySelf: true, emitEvent: false });
    this.onChange(value);
    this.closePopover();
  }

  public closePopover() {
    this.popover.close();
  }
}
